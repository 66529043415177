import { Box, BoxProps } from '@chakra-ui/react';
import { SwiperSlide } from 'swiper/react';
import DisplayFileItem from '../DisplayFileItem';
import { SLIDE_SIZE, SLIDE_STYLE } from '../RichMessageEditor';
import { IRichTextAttachment, IRichTextAttachmentInput } from '../RichtextEditor/utils/types';
import Swiper, { SCROLLBAR_MODULE } from '../Swiper';

type DisplayOverviewProps = {
  color?: BoxProps['color'];
  value?: string | null;
  attachments?: (IRichTextAttachment | IRichTextAttachmentInput)[];
};

const DisplayOverview = (props: DisplayOverviewProps) => {
  const { value, attachments, color } = props;

  return (
    <Box mx='5'>
      <Box color={color ?? 'gray.800'} dangerouslySetInnerHTML={{ __html: value ?? '' }} />
      {attachments && (
        <Box mt={2}>
          <Swiper slideSize={SLIDE_SIZE} modules={[SCROLLBAR_MODULE]}>
            {attachments.map((attachment, index) => (
              <SwiperSlide key={index} style={SLIDE_STYLE}>
                <DisplayFileItem
                  key={attachment.fileId}
                  fileId={attachment.fileId}
                  contentType={attachment.contentType}
                  name={attachment.name}
                  path={attachment.path ?? ''}
                  size={SLIDE_SIZE}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      )}
    </Box>
  );
};
export default DisplayOverview;
