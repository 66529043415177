import { $generateHtmlFromNodes } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { EditorState } from 'lexical';

type OnChangeProps = {
  onChange: (htmlText: string) => void;
};

const CustomOnChangePlugin = ({ onChange }: OnChangeProps) => {
  const [editor] = useLexicalComposerContext();

  const handleOnChange = (editorState: EditorState) => {
    editorState.read(() => {
      editor.getEditorState().read(() => {
        const html = $generateHtmlFromNodes(editor);
        onChange(html);
      });
    });
  };

  return <OnChangePlugin onChange={handleOnChange} />;
};

export default CustomOnChangePlugin;
