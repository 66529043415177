import { Box, Collapse, Flex } from '@chakra-ui/react';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { FC, useCallback, useEffect, useState } from 'react';

import BottomToolbarPlugin from '../RichtextEditor/plugins/BottomToolbar';
import FloatingLinkEditorPlugin from '../RichtextEditor/plugins/FloatingLink';
import TopToolbarPlugin from '../RichtextEditor/plugins/TopToolbar';

import { IFile } from '@/utils/file/type';
import { LocalAttachmentType } from '../RichMessageEditor';
import DisplayAttachments from '../RichtextEditor/components/DisplayAttachments';
import DefaultNodes from '../RichtextEditor/nodes/DefaultNodes';
import CommonPlugins from '../RichtextEditor/plugins/CommonPlugins';
import InsertInitialHtmlPlugin from '../RichtextEditor/plugins/InsertInitialHtml';
import RichTextEditorTheme from '../RichtextEditor/utils/Theme';
import {
  IRichTextAttachment,
  IRichTextAttachmentInput,
  RichTextEditorMode,
} from '../RichtextEditor/utils/types';
import DisplayOverview from './DisplayOverview';
import CustomOnChangePlugin from './plugins/CustomOnChangePlugin';

export type InputType = {
  htmlText: string; // HTMLの情報が入っている
  attachments: IRichTextAttachmentInput[];
  deletedAttachmentFileIds?: string[];
};

type OverviewTextEditorType = {
  value?: string | null;
  placeholder?: string;
  onChange?: (input: InputType) => void;
  mode?: RichTextEditorMode;
  attachments?: IRichTextAttachment[];
};

const OverviewTextEditor: FC<OverviewTextEditorType> = (props: OverviewTextEditorType) => {
  const { value, placeholder, mode = 'add', attachments, onChange } = props;

  const onError = (error: Error) => {
    console.error(error);
  };

  // 表示するために変換した添付ファイル
  const [localAttachments, setLocalAttachments] = useState<LocalAttachmentType>(attachments ?? []);

  // 追加された添付ファイル
  const [localAddedAttachments, setLocalAddedAttachments] = useState<IRichTextAttachmentInput[]>(
    []
  );

  // 削除された添付ファイル
  const [localRemovedAttachmentFileIds, setLocalRemovedAttachmentFileIds] = useState<string[]>([]);

  const [showTopToolbar, setShowTopToolbar] = useState<boolean>(false);

  const initialConfig: InitialConfigType = {
    namespace: 'OverviewTextEditor',
    theme: RichTextEditorTheme,
    nodes: [...DefaultNodes],
    onError,
  };

  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement>();

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const onAddFiles = (files: IFile[]) => {
    files.forEach(({ file, fileData, id }) => {
      setLocalAttachments((prevAttachments) => [
        ...prevAttachments,
        { contentType: file.type, src: fileData, name: file.name, fileId: id },
      ]);
      setLocalAddedAttachments((prevAttachmentInputs) => [
        ...prevAttachmentInputs,
        { contentType: file.type, name: file.name, fileId: id, src: fileData },
      ]);
    });
  };

  const removeFile = useCallback(
    (index: number) => {
      const attachments = [...localAttachments];

      const deletedAttachments = attachments.splice(index, 1);
      setLocalAttachments(attachments);

      if (deletedAttachments[0]) {
        const attachment = deletedAttachments[0] as IRichTextAttachment;
        if (attachment.id === undefined) {
          // 追加するファイルから削除
          setLocalAddedAttachments((prev) => {
            const index = prev.findIndex((prev) => prev.fileId === attachment.fileId);
            const newList = [...prev];
            newList.splice(index, 1);
            return newList;
          });
          return;
        }
        setLocalRemovedAttachmentFileIds((prev) => [...prev, attachment.fileId]);
      }
    },
    [localAttachments]
  );

  const onToggleFormatClick = () => {
    setShowTopToolbar((prev) => !prev);
  };

  const handleChange = (htmlText: string) => {
    if (mode === 'display') return;
    if (!!onChange) {
      onChange({
        htmlText,
        attachments: localAddedAttachments,
        deletedAttachmentFileIds: localRemovedAttachmentFileIds,
      });
    }
  };

  const handleFileChange = useCallback(
    (
      localAddedAttachments: IRichTextAttachmentInput[],
      localRemovedAttachmentFileIds: string[]
    ) => {
      if (mode === 'display') return;
      if (!!onChange) {
        onChange({
          htmlText: value ?? '',
          attachments: localAddedAttachments,
          deletedAttachmentFileIds: localRemovedAttachmentFileIds,
        });
      }
    },
    [mode, onChange, value]
  );

  useEffect(() => {
    handleFileChange(localAddedAttachments, localRemovedAttachmentFileIds);
  }, [handleFileChange, localAddedAttachments, localRemovedAttachmentFileIds]);

  return (
    <Box>
      <LexicalComposer initialConfig={initialConfig}>
        {mode === 'display' ? (
          <DisplayOverview value={value} attachments={attachments} />
        ) : (
          <Box p={1} border='1px' borderRadius='md' borderColor='gray.300'>
            <Collapse in={showTopToolbar} animateOpacity>
              <Flex>
                <TopToolbarPlugin />
              </Flex>
            </Collapse>
            <Box position='relative'>
              <RichTextPlugin
                contentEditable={
                  <Box as='div' ref={onRef} maxH={{ base: '72px', md: '180px' }} overflowY='auto'>
                    <ContentEditable className='richtext-editor' />
                  </Box>
                }
                placeholder={
                  <Box position='absolute' top='3' left='3' color='gray.500' zIndex='0'>
                    {placeholder}
                  </Box>
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
              <DisplayAttachments localAttachments={localAttachments} removeFile={removeFile} />

              <Flex flex='1' justifyContent='end' mt='2'>
                <BottomToolbarPlugin
                  flex='1'
                  isMentionDisabled
                  isActiveToggleFormat={showTopToolbar}
                  onFilesAdded={onAddFiles}
                  onToggleFormatClick={onToggleFormatClick}
                />
              </Flex>
            </Box>
          </Box>
        )}
        <CommonPlugins />
        <InsertInitialHtmlPlugin key={mode} value={value} />
        <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
        <CustomOnChangePlugin onChange={handleChange} />
      </LexicalComposer>
    </Box>
  );
};

export default OverviewTextEditor;
