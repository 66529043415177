import {
  CheckListCustomFieldTrigger,
  CheckListCustomFieldTriggerCondition,
} from '@/modules/checkList';
import { isNotNullish } from '@/utils/common/isNullish';
import {
  Box,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
} from '@chakra-ui/react';
import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { MdInfo } from 'react-icons/md';

type Props = {
  activatedTriggers: CheckListCustomFieldTrigger[];
  options: { id: number; value: string }[];
};

export const CheckListTriggeredAlertIcon = ({ activatedTriggers, options }: Props) => {
  const { t } = useTranslation();
  if (activatedTriggers.length === 0) {
    return null;
  }

  return (
    <Popover trigger='hover'>
      <PopoverTrigger>
        <IconButton aria-label='info' variant='link' colorScheme='red' minW={0} icon={<MdInfo />} />
      </PopoverTrigger>
      <PopoverContent>
        <Stack>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>{t('trigger.title')}</PopoverHeader>
          <Box px={2} pb={2}>
            {activatedTriggers.map((trigger, i) => (
              <div key={i}>{renderTrigger(t, trigger.condition, options)}</div>
            ))}
          </Box>
        </Stack>
      </PopoverContent>
    </Popover>
  );
};

const renderTrigger = (
  t: TFunction,
  trigger: CheckListCustomFieldTriggerCondition,
  options: Props['options']
) => {
  const {
    numberBetween,
    numberGreaterThan,
    numberGreaterThanOrEqualTo,
    numberLessThan,
    numberLessThanOrEqualTo,
    numberNotBetween,
    selectInOptionIds,
    selectNotInOptionIds,
  } = trigger;

  if (isNotNullish(numberBetween)) {
    return `${t('trigger.condition.between')}: ${numberBetween.min} ~ ${numberBetween.max}`;
  }
  if (isNotNullish(numberGreaterThan)) {
    return `${t('trigger.condition.greater-than')}: ${numberGreaterThan}`;
  }
  if (isNotNullish(numberGreaterThanOrEqualTo)) {
    return `${t('trigger.condition.greater-than-or-equal-to')}: ${numberGreaterThanOrEqualTo}`;
  }
  if (isNotNullish(numberLessThan)) {
    return `${t('trigger.condition.less-than')}: ${numberLessThan}`;
  }
  if (isNotNullish(numberLessThanOrEqualTo)) {
    return `${t('trigger.condition.less-than-or-equal-to')}: ${numberLessThanOrEqualTo}`;
  }
  if (isNotNullish(numberNotBetween)) {
    return `${t('trigger.condition.not-between')}: ${numberNotBetween.min} ~ ${
      numberNotBetween.max
    }`;
  }
  if (isNotNullish(selectInOptionIds)) {
    return `${t('trigger.condition.in')}: ${selectInOptionIds
      .map((id) => options.find((opt) => opt.id === id)?.value)
      .join(', ')}`;
  }
  if (isNotNullish(selectNotInOptionIds)) {
    return `${t('trigger.condition.not-in')}: ${selectNotInOptionIds
      .map((id) => options.find((opt) => opt.id === id)?.value)
      .join(', ')}`;
  }
};
