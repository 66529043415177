import {
  CheckListCustomFieldTrigger,
  ICheckListCustomField,
  ICustomFieldSelectValue,
} from '@/modules/checkList';
import { isNotNullish, isNullish } from '@/utils/common/isNullish';
import { CheckListFormValueType } from '@/utils/customFields/checkListCustomFields';

export function checkActivatedTrigger(
  field: ICheckListCustomField,
  formValue: CheckListFormValueType
): CheckListCustomFieldTrigger[] {
  return (
    field.triggers?.flatMap((trigger) => {
      switch (field.type) {
        case 'select': {
          const value = formValue.customFieldSelectValues.find(
            (value) => value.customFieldId === field.id
          );

          if (isNullish(value)) {
            return [];
          }

          return evaluateSelectTrigger(trigger, value) ? [trigger] : [];
        }
        case 'int':
          const intValue = formValue.customFieldIntValues.find(
            (value) => value.customFieldId === field.id
          );

          if (isNullish(intValue)) {
            return [];
          }

          return evaluateNumberTrigger(intValue.value, trigger) ? [trigger] : [];
        case 'float':
          const floatValue = formValue.customFieldFloatValues.find(
            (value) => value.customFieldId === field.id
          );

          if (isNullish(floatValue)) {
            return [];
          }

          return evaluateNumberTrigger(floatValue.value, trigger) ? [trigger] : [];
        default:
          return [];
      }
    }) ?? []
  );
}

function evaluateNumberTrigger(value: number, trigger: CheckListCustomFieldTrigger): boolean {
  const condition = trigger.condition;

  if (!condition) {
    return false;
  }

  if (isNotNullish(condition.numberLessThan) && value < condition.numberLessThan) {
    return true;
  }

  if (
    isNotNullish(condition.numberLessThanOrEqualTo) &&
    value <= condition.numberLessThanOrEqualTo
  ) {
    return true;
  }

  if (isNotNullish(condition.numberGreaterThan) && value > condition.numberGreaterThan) {
    return true;
  }

  if (
    isNotNullish(condition.numberGreaterThanOrEqualTo) &&
    value >= condition.numberGreaterThanOrEqualTo
  ) {
    return true;
  }

  if (
    isNotNullish(condition.numberBetween) &&
    condition.numberBetween.min <= value &&
    value <= condition.numberBetween.max
  ) {
    return true;
  }

  if (
    isNotNullish(condition.numberNotBetween) &&
    !(condition.numberNotBetween.min <= value && value <= condition.numberNotBetween.max)
  ) {
    return true;
  }

  return false;
}

function evaluateSelectTrigger(
  trigger: CheckListCustomFieldTrigger,
  value: ICustomFieldSelectValue
): boolean {
  if (
    isNotNullish(trigger.condition.selectInOptionIds) &&
    trigger.condition.selectInOptionIds.includes(value.optionId)
  ) {
    return true;
  }

  if (isNotNullish(trigger.condition.selectNotInOptionIds)) {
    return !trigger.condition.selectNotInOptionIds.includes(value.optionId);
  }

  return false;
}
